<template lang="pug">
include ../../../configs/template
form.pa-4
  div(v-if="checkAccess('sailorPassport', 'preVerification', sailorDocument) || checkAccess('sailorPassport', 'editStatus', sailorDocument)")
    +select-validation('body.status_document','listStatus','status','nameLang','["required"]')
  div(v-if="checkAccess('sailorPassport', 'preVerification', sailorDocument) && body.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR_PASSPORT.INVALID")
    Reject
  v-btn(v-if="body.status_document !== STATUSES_STATEMENT_CONSTANTS.SAILOR_PASSPORT.INVALID"
    :loading="buttonLoader"
    @click="checkAccess('sailorPassport', 'preVerification', sailorDocument) || checkAccess('sailorPassport', 'editStatus', sailorDocument) ? saveNewStatus() : checkSave()"
    color="success") {{ checkAccess('sailorPassport', 'preVerification', sailorDocument) || checkAccess('sailorPassport', 'editStatus', sailorDocument) ? $t('save') : $t('setVerify') }}
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapGetters, mapState } from 'vuex'
import { clearBody } from '@/mixins/main'
import { sailorDocumentChangedStatus } from '@/mixins/validationRules'
import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
export default {
  name: 'SailorPassportEditStatus',
  components: {
    Reject: () => import('./Reject.vue')
  },
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: {
        status_document: this.sailorDocument.status_document
      },
      buttonLoader: false,
      checkAccess,
      STATUSES_STATEMENT_CONSTANTS
    }
  },
  computed: {
    ...mapState({
      id: state => state.sailor.sailorId,
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['statusChoose']),
    listStatus () {
      if (checkAccess('admin')) {
        return this.statusChoose('BackOffice')
          .concat(this.statusChoose('QualificationDoc'))
      } else if (checkAccess('verifierBackOfficeSecond')) {
        return this.statusChoose('QualificationDoc').filter(i => [7, 19, 20, 37].includes(i.id))
      } else return this.statusChoose('QualificationDoc')
    }
  },
  validations () { return sailorDocumentChangedStatus() },
  methods: {
    /** Verification confirmation */
    ...mapActions(['updateSailorPassportById']),
    checkSave () {
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('continueVerification'),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('setVerify')],
        dangerMode: true
      }).then((confirmation) => {
        if (confirmation) this.saveNewStatus()
      })
    },

    async saveNewStatus () {
      this.buttonLoader = true
      let data = {
        ...this.$route.params, body: { ...clearBody({ ...this.body }) }
      }
      const response = await this.updateSailorPassportById(data)
      this.buttonLoader = false
      if (response.code === 200) {
        this.$parent.$parent.getData()
        if (this.sailorDocument.status_document === 34 && response.data.status_document !== 34) {
          this.$store.commit('decrementUserNotification', 'documents_on_verification')
        }
      }
    }
  }
}

</script>
